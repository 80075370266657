<template>
    <div class="zpanle">
        <div class="baccount-tit">
            <h3 class="g-title">绑定银行卡</h3>
        </div>
        <div class="bkcard-wrap">
            <div class="modiysucc-box minht">
                <ul class="modiysucc-list">
                    <li class="modiysucc-item">
                        <i class="jk-iconchenggong1"></i>
                        <span>添加成功</span>
                    </li>
                    <li>已成功添加银行卡</li>
                    <li>
                        <el-row>
                            <el-button
                                plain
                                @click="go_to()"
                            >返回我的钱包</el-button>
                        </el-row>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
let id = 0;
export default {
    name: "addsuccess",
    metaInfo() {
        return {
            title: "添加成功 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    data() {
        return {};
    },
    methods: {
        go_to() {
            this.$router.push("/user/wallet/list");
        }
    }
};
</script>

<style lang="less">
@import "../../../styles/mywallet.less";
</style>




